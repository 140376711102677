<template>
  <vs-card>
    <div slot="header" class="flex items-center justify-between mx-4 mt-4">
      <h3>{{ $t("Automation") }}</h3>
      <div class="flex items-center justify-between gap-x-4">
        <vs-button icon-pack="feather" icon="icon-file-text" @click="popupActive = true">{{
          $t("AddAutomation") }}</vs-button>
      </div>
    </div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-table hoverFlat stripe :data="automationData" :noDataText="$t('EmptyTable')" :notSpacer="true" class="userTable">
          <template slot="thead">
            <vs-th>Título</vs-th>
            <vs-th>Chatbot</vs-th>
            <vs-th>Gatilho</vs-th>
            <vs-th>Ação</vs-th>
            <vs-th>Tempo</vs-th>
            <vs-th>Intenções</vs-th>
            <vs-th>Entidades chave</vs-th>
            <vs-th>Conversas</vs-th>
            <vs-th>Criado por</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" :class="tr.actions.status === 2 ? 'userDisabled' : ''">
              <vs-td :data="tr.title" class="text-truncate">
                {{ tr.title }}
              </vs-td>
              <vs-td :data="tr.chat"> {{ tr.chat }} </vs-td>
              <vs-td :data="tr.trigger" :title="tr.trigger">
                {{ tr.trigger }}
              </vs-td>
              <vs-td :data="tr.action">
                {{ checkAction(tr.action) }}
              </vs-td>
              <vs-td :data="tr.time">
                {{ tr.timeHour }}h:{{ tr.timeMinute }}m
              </vs-td>
              <vs-td :data="tr.intentions" class="text-truncate" :title="tr.intentions">
                {{ formatArray(tr.intentions) }}
              </vs-td>
              <vs-td :data="tr.entities" class="text-truncate" :title="tr.entities">
                {{ formatArray(tr.entities) }}
              </vs-td>
              <vs-td :data="tr.stages"> {{ formatStages(tr.stages) }} </vs-td>
              <vs-td :data="tr.createdBy"> {{ tr.createdBy }} </vs-td>
              <vs-td :data="tr.actions">
                <div class="flex items-center">
                  <vs-button icon-pack="feather" icon="icon-search" type="flat" color="dark"
                    @click="openAutomationLogsPopup(tr.actions._id)" />
                  <vs-dropdown v-if="$acl.check('admin')" vs-custom-content vs-trigger-click
                    class="dark-btn rounded-md cursor-pointer">
                    <vs-button icon-pack="feather" icon="icon-more-vertical" type="flat" color="dark" />
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="
                        openAutomationWarningPopup(
                          tr.actions._id,
                          tr.actions.status
                        )
                        ">
                        {{ tr.actions.status === 2 ? "Ativar" : "Desativar" }}
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="openViewAutomationPopup(tr.actions._id)">
                        {{ $t("View") }}
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="confirmDeleteRecord(tr.actions._id)">
                        {{ $t("Delete") }}
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <vs-popup :title="$t('Automation')" v-if="warningPopupActive" :active.sync="warningPopupActive">
                  <WarningPopup :statusWarningMessage="currentStatus === 2
                    ? $t('ActivateAutomation')
                    : $t('DeactivateAutomation')
                    " :currentStatus="currentStatus" :automationId="currentAutomationId"
                    @refreshTable="fetchAutomationData()" @closeWarningPopup="closeWarningPopup()" />
                </vs-popup>
              </vs-td>
            </vs-tr>
            <vs-popup :title="$t('Automation')" v-if="viewAutomationPopupActive"
              :active.sync="viewAutomationPopupActive">
              <ViewAutomationPopup :automationId="currentAutomationId" />
            </vs-popup>
          </template>
        </vs-table>
      </vs-col>
      <vs-popup :title="$t('Automation')" v-if="popupActive" :active.sync="popupActive">
        <createAutomationPopup :intentions="intentions" :entities="entities" @closeCreatePopup="closeCreatePopup()"
          @refreshTable="fetchAutomationData()" />
      </vs-popup>
      <vs-popup :title="$t('AutomationReport')" :active.sync="popupLogsActive" fullscreen>
        <vs-row>
          <vs-col class="mt-5">
            <vs-table :data="automationQueueData" stripe :hoverFlat="true">
              <template v-slot:thead>
                <vs-th></vs-th>
                <vs-th>{{ $t("Name") }}</vs-th>
                <vs-th>{{ $t("Phone") }}</vs-th>
                <vs-th>{{ $t("FinishingDate") }}</vs-th>
                <vs-th>{{ $t("Status") }}</vs-th>
                <vs-th>{{ $t("ResponseMessage") }}</vs-th>
              </template>
              <template v-slot:default="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="indextr + 1">
                    {{ indextr + 1 }}
                  </vs-td>
                  <vs-td>
                    {{ tr.contactData.name }}
                  </vs-td>
                  <vs-td>
                    {{ formatPhone(tr.contactData.phone) }}
                  </vs-td>
                  <vs-td>
                    {{
                      tr.automationConclusionDate
                        ? fullDate(tr.automationConclusionDate)
                        : "-"
                    }}
                  </vs-td>
                  <vs-td :data="tr.status">
                    <vs-chip color="warning" v-if="tr.status === 1">
                      {{ $t("Waiting") }}
                    </vs-chip>
                    <vs-chip color="success" v-if="tr.status === 5">
                      {{ $t("Success") }}
                    </vs-chip>
                    <vs-chip color="danger" v-else-if="tr.status === 6">
                      {{ $t("Error") }}
                    </vs-chip>
                  </vs-td>
                  <vs-td>
                    {{
                      statusResponseMap[tr.statusResponse]
                        ? statusResponseMap[tr.statusResponse]
                        : tr.statusResponse
                    }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <vs-pagination
              class="mt-4"
              :total="totalPages"
              v-model="currentLogsPage"
            />
          </vs-col>
        </vs-row>
      </vs-popup>
    </vs-row>
  </vs-card>
</template>

<script>
import CreateAutomationPopup from "../../../components/createAutomationPopup.vue";
import ViewAutomationPopup from "../../../components/viewAutomationPopup.vue";
import WarningPopup from "../../../components/warningPopup.vue";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";

export default {
  components: {
    CreateAutomationPopup,
    WarningPopup,
    ViewAutomationPopup,
  },
  data() {
    return {
      automationData: [],
      automationQueueData: [],
      intentions: [],
      entities: [],
      message: "",
      popupActive: false,
      warningPopupActive: false,
      viewAutomationPopupActive: false,
      popupLogsActive: false,
      currentAutomationId: null,
      currentStatus: null,
      currentLogsPage: 1,
      totalPages: 1,
      statusResponseMap: {
        "Re-engagement message": this.$t("ReengamentMessage"),
        "Message undeliverable": this.$t("MessageUndeliverable"),
        "Conversation lastMessageDate has been updated":
          this.$t("LastMessageUpdate"),
        Success: this.$t("MessageSent"),
      },
      stages: {
        1: "Campanha",
        2: "Aguardando Atendimento",
        3: "Em Atendimento",
      },
    };
  },
  watch: {
    popupActive() {
      if (this.popupActive && this.automationData.length >= 10) {
        this.popupActive = false;
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("AutomationLimitReached"),
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async currentLogsPage() {
      this.fetchAutomationLogs(this.currentAutomationId, this.currentLogsPage);
    },
  },
  methods: {
    checkAction(actionLabel) {
      let label = "";
      switch (actionLabel) {
        case "sendMessage":
          label = "Enviar mensagem";
          break;
        case "sendTemplate":
          label = "Enviar template";
          break;
        case "closeConversation":
          label = "Encerrar conversa";
          break;
      }
      return label;
    },
    closeCreatePopup() {
      this.popupActive = false;
    },
    closeWarningPopup() {
      this.warningPopupActive = false;
    },
    openViewAutomationPopup(automationId) {
      this.currentAutomationId = automationId;
      this.viewAutomationPopupActive = true;
    },
    openAutomationLogsPopup(automationId) {
      this.fetchAutomationLogs(automationId);
      this.popupLogsActive = true;
    },
    openAutomationWarningPopup(automationId, status) {
      this.currentAutomationId = automationId;
      this.currentStatus = status;
      this.warningPopupActive = true;
    },
    formatArray(array) {
      return array.length ? array.join(", ") : "-";
    },
    formatStages(stageData) {
      if (stageData.length > 1) {
        return stageData.map((stage) => this.stages[stage]).join(", ");
      }
      return this.stages[stageData];
    },
    formatPhone(phone) {
      if (phone === undefined || phone === "" || phone === null) return phone;

      try {
        if (phone.startsWith("55")) {
          const isLongNumber = phone.length === 13;
          return (
            "+" +
            phone.slice(0, 2) +
            " (" +
            phone.slice(2, 4) +
            ") " +
            phone.slice(4, isLongNumber ? 9 : 8) +
            "-" +
            phone.slice(isLongNumber ? 9 : 8)
          );
        }
        const parsedNumber = parsePhoneNumber("+" + phone);

        if (
          !parsedNumber ||
          !parsedNumber.country ||
          !parsedNumber.nationalNumber
        ) {
          throw new Error("Invalid parsed phone number");
        }

        return (
          "+" +
          parsedNumber.countryCallingCode +
          " " +
          new AsYouType(parsedNumber.country).input(parsedNumber.nationalNumber)
        );
      } catch (error) {
        return phone; // Return the original phone as a fallback
      }
    },
    fullDate(dateString) {
      const date = new Date(dateString);
      const fullDate = date.toLocaleDateString();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");
      const fullDateWithHours = `${fullDate} ${hours}:${minutes}:${seconds}`;

      return `${fullDateWithHours}`;
    },
    async fetchAutomationData() {
      this.$vs.loading();

      const params = {
        acc: this.$store.state.acc.current_acc.id,
      };

      try {
        const response = await this.$http.get(`/p/automation/list`, { params });

        this.automationData = response.data.data.automations.map(
          (automation) => ({
            title: automation.title,
            chat: automation.chatInfo.name,
            action: automation.automationSetup.action,
            message: automation.automationSetup.message,
            trigger:
              automation.trigger === "inactivity"
                ? "Sem resposta"
                : automation.trigger,
            // timeDay: automation.automationSetup.timeDay,
            timeHour: automation.automationSetup.timeHour,
            timeMinute: automation.automationSetup.timeMinute,
            intentions: automation.automationSetup.intentions,
            entities: automation.automationSetup.entities,
            stages: automation.automationSetup.stages,
            createdBy: automation.userInfo.name,
            actions: {
              _id: automation._id,
              status: automation.status,
            },
          })
        );
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async fetchAutomationLogs(automationId, page) {
      this.currentAutomationId = automationId;
      this.$vs.loading();
      const params = {
        acc: this.$store.state.acc.current_acc.id,
        page: page || 1,
      };

      try {
        const response = await this.$http.get(
          `/p/automation/logs/${automationId}`,
          {
            params: params,
            headers: {
              Authorization: "Bearer " + this.$store.state.auth.accessToken,
              "Content-Type": "Application/json",
            },
          }
        );
        this.automationQueueData = response.data.data.automationQueue;
        this.totalPages = response.data.data.pages;
        this.currentLogsPage = response.data.data.currentPage;
      } catch (error) {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: this.$t("UnexpectedError"),
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    confirmDeleteRecord(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("ConfirmRemoveUserAccess"),
        text: this.$t("ConfirmRemoveAutomation"),
        accept: () => this.deleteRecord(id),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    async deleteRecord(id) {
      this.$vs.loading();

      const params = {
        acc: this.$store.state.acc.current_acc.id,
      };

      try {
        await this.$http.delete(`/p/automation/delete/${id}`, {
          data: params,
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "Application/json",
          },
        });
        this.$vs.loading.close();
        this.showDeleteSuccess();
        this.fetchAutomationData();
      } catch (error) {
        this.$vs.loading.close();
      }
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        title: this.$t("Confirmation"),
        text: this.$t("AutomationDeletedSuccessfully"),
        position: "top-right",
        time: 4000,
      });
    },
  },
  mounted() {
    this.fetchAutomationData();
  },
};
</script>

<style>
.userTable .userDisabled td span {
  color: #d2d2d2;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>
